import React from "react";
import "./CampaignsScreen.css";
import MoreWaysToDonate from "../../../../../../components/MoreWaysToDonate/MoreWaysToDonate";
import FundAndCampaignCard from "../../../../../../components/FundAndCampaignCard/FundAndCampaignCard";
import { useSelector } from "react-redux";

export const convertStringToTags = (htmlString) => {
  // Create a container to parse the HTML string
  const parser = new DOMParser();
  const htmlDocument = parser.parseFromString(htmlString, "text/html");

  // Extract all <p> elements
  const pElements = htmlDocument.querySelectorAll("p");

  // Convert NodeList to an array of JSX elements
  const reactElements = Array.from(pElements).map((p, index) => (
    <p key={index}>{p.textContent}</p>
  ));

  return reactElements;
};

const CampaignsScreen = ({
  currentFund,
  fundName,
  funds,
  fundSlug,
  navigate,
  filteredCampaigns,
  isAdditionalCause
}) => {
  const campaignBlocks = useSelector((state) =>
    state.fundsAndCampaigns.campaignBlocks.filter(
      (c) => c.showOnFundId == currentFund.fundId
    )
  );

  const handleCampaignClick = (campaign) => {
    if (campaignBlocks.length > 0 && campaign?.showOnFundId) {
      navigate(`/program/${currentFund.slug}/${campaign?.slug}`);
      return;
    }
    const currentfundSlug = funds.find(
      (fund) => fund.fundId === campaign?.fundId
    ).slug;
    const campaignSlug = campaign?.slug;

    navigate(`/program/${currentfundSlug}/${campaignSlug}`);
  };

  const whereMostNeededSlug = filteredCampaigns.find(
    (campaign) =>
      campaign.name && campaign.name.toLowerCase().includes("where most")
  )?.slug;

  return (
    <>
      <section className="paddingTop--small paddingBottom--regular section section--programCauses">
        <div className="row paddingBottom--extraSmall sectionTitle">
          <h2>Select A Cause To Support</h2>
        </div>

        <div className="sectionOptions">
          {isAdditionalCause ? (
            <FundAndCampaignCard
              noImageChange
              name={isAdditionalCause.name}
              color={isAdditionalCause.color}
              iconSVGCode={isAdditionalCause.iconSVGCode}
              staticBackground={isAdditionalCause.staticBackground}
              shadow={true}
              hoverColor={isAdditionalCause.hoverColor}
              onClick={() => handleCampaignClick(isAdditionalCause)}
              contentColorIdleOnHover={true}
              simple
            />
          ) : (
            [...filteredCampaigns, ...campaignBlocks].map((campaign) => (
              <FundAndCampaignCard
                noImageChange
                name={campaign.name}
                color={campaign.color}
                iconSVGCode={campaign.iconSVGCode}
                staticBackground={campaign.staticBackground}
                shadow={true}
                hoverColor={campaign.hoverColor}
                onClick={() => handleCampaignClick(campaign)}
                contentColorIdleOnHover={true}
                simple
              />
            ))
          )}
        </div>
      </section>
      {currentFund?.description && (
        <section class="paddingBottom--regular section section--causeInfo">
          <div class="wrapper">
            <h2
              style={{
                "--color": currentFund?.color
              }}
            >
              About {currentFund?.name}
            </h2>

            <p>{currentFund?.description}</p>

            <p>
              <a
                href={`https://pennyappeal.ca/${currentFund?.slug}/`}
                class="btn--tertiary btn--lightGrey learnMoreBtn"
                target="_blank"
              >
                Learn More
              </a>
            </p>
          </div>
        </section>
      )}

      <section className="mostNeededCTA_wrapper">
        <MoreWaysToDonate
          color={currentFund.color}
          handleClick={() => {
            if (whereMostNeededSlug) {
              navigate(`/program/${fundSlug}/${whereMostNeededSlug}`);
            }
          }}
        />
      </section>
    </>
  );
};

export default CampaignsScreen;
