// CheckoutHeader.jsx
import React from "react";
import "./Checkout.css";

const CheckoutHeader = ({ amount, frequency, donation, program, details }) => (
  <div className="checkoutHeader">
    <a
      href="https://donate.pennyappeal.ca/"
      className="btn--tertiary btn--grey pageHeader__goBack"
    >
      Go Back
      <svg
        className="svg-inline--fa fa-undo"
        aria-hidden="true"
        focusable="false"
        data-prefix="far"
        data-icon="undo"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M40 224c-13.3 0-24-10.7-24-24L16 56c0-13.3 10.7-24 24-24s24 10.7 24 24l0 80.1 20-23.5C125 63.4 186.9 32 256 32c123.7 0 224 100.3 224 224s-100.3 224-224 224c-50.4 0-97-16.7-134.4-44.8c-10.6-8-12.7-23-4.8-33.6s23-12.7 33.6-4.8C179.8 418.9 216.3 432 256 432c97.2 0 176-78.8 176-176s-78.8-176-176-176c-54.3 0-102.9 24.6-135.2 63.4l-.1 .2s0 0 0 0L93.1 176l90.9 0c13.3 0 24 10.7 24 24s-10.7 24-24 24L40 224z"
        ></path>
      </svg>
    </a>
    <ul className="breadcrumbs">
      <li className="breadcrumb">
        <a href="/">Main</a>
      </li>
      <li className="breadcrumb">
        <span className="breadcrumb__active">Checkout</span>
      </li>
    </ul>
    <h1 className="checkoutHeader__title">
      <span>You're about to donate</span> ${parseInt(amount)} (
      {frequency || "One Time"})
    </h1>
    <p className="checkoutHeader__message">
      Thank you for making a difference to communities at home and across the
      world. Please fill out the form below to donate.
    </p>
    <div className="checkoutBreakdown">
      <div
        className="checkoutBreakdown__list checkoutBreakdown__list--active"
        style={{ display: "block" }}
      >
        <p>
          <strong>Program:</strong> {program} <br />
          <strong>Cause:</strong> {donation} <br />
          <strong>Frequency:</strong> {frequency || "One Time"}
        </p>
        <h4>Donation Breakdown</h4>
        <p>
          {details.split(";").map((d, idx) => (
            <span key={idx}>{d}</span>
          ))}
        </p>
      </div>
    </div>
  </div>
);

export default CheckoutHeader;
