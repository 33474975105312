// CustomPaymentMethod.jsx
import React from "react";
import { PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import "./Checkout.css";

const CustomPaymentMethod = ({ paymentRequest }) => {
  if (!paymentRequest) {
    return (
      <div className="payment-request-wrapper errorbox">
        <p className="payment-request-unavailable">
          Google/Apple Pay is not available on this device/browser.
        </p>
      </div>
    );
  }
  return (
    <div className="payment-request-wrapper" style={{ marginTop: "1rem" }}>
      <PaymentRequestButtonElement options={{ paymentRequest }} />
    </div>
  );
};

export default CustomPaymentMethod;