// frequencyUtils.js
/**
 * Returns the correct frequency key for monthly/annually or monthlyWithProcessingFee, etc.
 */
export const getFrequencyKey = (frequency, isProcessingFeeSelected) => {
    const freqNormalized = frequency.toLowerCase();
    if (isProcessingFeeSelected && ["monthly", "annually"].includes(freqNormalized)) {
      return freqNormalized + "WithProcessingFee";
    }
    return freqNormalized;
  };