// ExtraChoice.jsx
import React from "react";
import CustomCheckbox from "../../../../../components/CustomCheckbox/CustomCheckbox";
import "./Checkout.css";

const ExtraChoice = ({
  zakatEligible,
  isZakatSelected,
  setIsZakatSelected,
  optOutOfEmailsSelected,
  setOptOutOfEmailsSelected,
  isProcessingFeeSelected,
  setIsProcessingFeeSelected
}) => (
  <div className="extraChoice-container">
    <div
      className="extraChoice-item"
      onClick={() => setOptOutOfEmailsSelected(!optOutOfEmailsSelected)}
    >
      <CustomCheckbox isSelected={optOutOfEmailsSelected} />
      <label className="extraChoice-label">
        I WANT TO OPT OUT OF RECEIVING EMAILS
      </label>
    </div>
    {zakatEligible && (
      <div
        className="extraChoice-item"
        onClick={() => setIsZakatSelected(!isZakatSelected)}
      >
        <CustomCheckbox isSelected={isZakatSelected} />
        <label className="extraChoice-label">
          I WANT TO DESIGNATE THIS DONATION AS ZAKAT
        </label>
      </div>
    )}
    <div
      className="extraChoice-item"
      onClick={() => setIsProcessingFeeSelected(!isProcessingFeeSelected)}
    >
      <CustomCheckbox isSelected={isProcessingFeeSelected} />
      <label className="extraChoice-label">
        I WANT TO COVER THE PROCESSING FEES
      </label>
    </div>
    <p className="extraChoice-description">
      Covering the fees will add $7.40 (2.2% + $0.80) to the donation total.
      Thank you for helping to keep Penny Appeal Canada running!
    </p>
  </div>
);

export default ExtraChoice;
