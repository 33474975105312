// formUtils.js
/**
 * Validates the form fields in the checkout. Returns {isValid, errors}
 */
export const validateForm = (formValues) => {
    const errors = {};
    let isValid = true;
  
    const requiredFields = [
      "firstName", "lastName", "email", "phone",
      "addressLine1", "city", "postalCode",
      "selectedRegion", "selectedCountry"
    ];
  
    requiredFields.forEach((field) => {
      if (!formValues[field]) {
        isValid = false;
        errors[field] = `${field} is required`;
      }
    });
  
    if (formValues.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.email)) {
      isValid = false;
      errors.email = "Invalid email format";
    }
  
    if (formValues.unitNumber && isNaN(formValues.unitNumber)) {
      isValid = false;
      errors.unitNumber = "Unit number must be a number";
    }
  
    return { isValid, errors };
  };