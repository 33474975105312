// Notes.jsx
import React from "react";
import "./Checkout.css";

const Notes = () => (
  <section className="section section--checkoutDisclaimer">
    <div className="wrapper">
      <p style={{ textAlign: "left" }}>
        Note:
        <br />
        1. For Donors using Google Pay or Apple Pay, please ensure the address used to donate here matches your default address...
      </p>
      <p style={{ textAlign: "left" }}>
        2. Penny Appeal Canada is committed to managing the resources entrusted to us in a transparent and responsible manner...
      </p>
      <p style={{ textAlign: "left" }}>
        3. By providing your contact details above you agree to our{" "}
        <a href="https://pennyappeal.ca/privacy-policy/" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        .
      </p>
    </div>
  </section>
);

export default Notes;