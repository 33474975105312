// Details.jsx
import React, { useRef, useEffect, useState } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import {
  MdPerson,
  MdEmail,
  MdPhone,
  MdLocationOn,
  MdDoorBack,
  MdLocationCity,
  MdMarkunreadMailbox,
  MdMap,
  MdPublic
} from "react-icons/md";
import CustomSpinner from "../../../../../components/CustomSpinner/CustomSpinner";
import { verifyEmail, verifyPhone } from "../../../../../api/verification";
import "./Checkout.css";

const Details = ({
  isLoaded,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors
}) => {
  const addressInputRef = useRef(null);
  const [emailLoading, setEmailLoading] = useState(false);
  const [phoneLoading, setPhoneLoading] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);

  useEffect(() => {
    if (isLoaded && addressInputRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        addressInputRef.current,
        {
          types: ["address"]
        }
      );
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        const addressComponents = place.address_components || [];
        const address = {};

        addressComponents.forEach((component) => {
          const types = component.types;
          if (types.includes("street_number"))
            address.streetNumber = component.long_name;
          if (types.includes("route")) address.route = component.long_name;
          if (types.includes("postal_code"))
            address.postalCode = component.long_name;
          if (types.includes("locality")) address.city = component.long_name;
          if (types.includes("subpremise")) address.unit = component.long_name;
          if (types.includes("administrative_area_level_1"))
            address.region = component.long_name;
          if (types.includes("country")) address.country = component.short_name;
        });

        setFormValues((prev) => ({
          ...prev,
          addressLine1: [address.streetNumber, address.route]
            .filter(Boolean)
            .join(" "),
          postalCode: address.postalCode || "",
          city: address.city || "",
          unitNumber: address.unit || "",
          selectedRegion: address.region || "",
          selectedCountry: address.country || "CA"
        }));
      });
    }
  }, [isLoaded, setFormValues]);

  const handleInputChange = (field, value) => {
    setFormValues((prev) => ({ ...prev, [field]: value }));
    setFormErrors((prev) => ({ ...prev, [field]: "" }));
  };

  const handleEmailBlur = async () => {
    setEmailLoading(true);
    try {
      const res = await verifyEmail(formValues.email);
      setEmailError(res.isValid ? null : res.message);
    } catch (error) {
      console.log("Error validating email:", error);
    } finally {
      setEmailLoading(false);
    }
  };

  const handlePhoneBlur = async () => {
    setPhoneLoading(true);
    try {
      const res = await verifyPhone(
        formValues.phone,
        formValues.selectedCountry
      );
      setPhoneError(res.isValid ? null : res.message);
    } catch (error) {
      console.log("Error validating phone:", error);
    } finally {
      setPhoneLoading(false);
    }
  };

  return (
    <div className="payment-selection-container">
      <p className="title" style={{ color: "#ef7c00", marginBottom: "20px" }}>
        Your Details
      </p>
      <form className="donation-form">
        <div className="details-form-row">
          {[
            {
              icon: MdPerson,
              placeholder: "First Name *",
              field: "firstName",
              required: true
            },
            {
              icon: MdPerson,
              placeholder: "Middle Name",
              field: "middleName",
              required: false
            },
            {
              icon: MdPerson,
              placeholder: "Last Name *",
              field: "lastName",
              required: true
            }
          ].map(({ icon: Icon, placeholder, field, required }, idx) => (
            <div
              className="input-with-icon"
              style={{ flexDirection: "column" }}
              key={idx}
            >
              <div className="input-with-icon">
                <Icon className="input-icon" />
                <input
                  type="text"
                  placeholder={placeholder}
                  value={formValues[field]}
                  onChange={(e) => handleInputChange(field, e.target.value)}
                  required={required}
                />
              </div>
              {formErrors[field] && (
                <div className="errorbox">{formErrors[field]}</div>
              )}
            </div>
          ))}
        </div>

        <div className="details-form-row" style={{ alignItems: "start" }}>
          <div className="input-with-icon" style={{ flexDirection: "column" }}>
            <div className="input-with-icon">
              <MdEmail className="input-icon" />
              <input
                type="email"
                placeholder="Email *"
                value={formValues.email}
                onChange={(e) => handleInputChange("email", e.target.value)}
                onBlur={handleEmailBlur}
                required
              />
            </div>
            {formErrors.email && (
              <div className="errorbox">{formErrors.email}</div>
            )}
            {emailLoading ? (
              <CustomSpinner />
            ) : (
              emailError && <div className="errorbox">{emailError}</div>
            )}
          </div>

          <div className="input-with-icon" style={{ flexDirection: "column" }}>
            <div className="input-with-icon">
              <MdPhone className="input-icon" />
              <input
                type="text"
                placeholder="Phone *"
                value={formValues.phone}
                onChange={(e) =>
                  handleInputChange("phone", e.target.value.replace(/\D/g, ""))
                }
                onBlur={handlePhoneBlur}
                required
              />
            </div>
            {formErrors.phone && (
              <div className="errorbox">{formErrors.phone}</div>
            )}
            {phoneLoading ? (
              <CustomSpinner />
            ) : (
              phoneError && <div className="errorbox">{phoneError}</div>
            )}
          </div>
        </div>

        <div className="details-form-row">
          <div className="input-with-icon" style={{ flexDirection: "column" }}>
            <div className="input-with-icon">
              <MdLocationOn className="input-icon" />
              <input
                type="text"
                placeholder="Address Line 1 *"
                value={formValues.addressLine1}
                onChange={(e) =>
                  handleInputChange("addressLine1", e.target.value)
                }
                required
                ref={addressInputRef}
              />
            </div>
            {formErrors.addressLine1 && (
              <div className="errorbox">{formErrors.addressLine1}</div>
            )}
          </div>
        </div>

        <div className="details-form-row">
          <div className="input-with-icon" style={{ flexDirection: "column" }}>
            <div className="input-with-icon">
              <MdLocationOn className="input-icon" />
              <input
                type="text"
                placeholder="Address Line 2"
                value={formValues.addressLine2}
                onChange={(e) =>
                  handleInputChange("addressLine2", e.target.value)
                }
              />
            </div>
          </div>
        </div>

        <div className="details-form-row">
          <div className="input-with-icon" style={{ flexDirection: "column" }}>
            <div className="input-with-icon">
              <MdDoorBack className="input-icon" />
              <input
                type="text"
                placeholder="Unit Number"
                value={formValues.unitNumber}
                onChange={(e) =>
                  handleInputChange(
                    "unitNumber",
                    e.target.value.replace(/\D/g, "")
                  )
                }
              />
            </div>
            {formErrors.unitNumber && (
              <div className="errorbox">{formErrors.unitNumber}</div>
            )}
          </div>

          <div className="input-with-icon" style={{ flexDirection: "column" }}>
            <div className="input-with-icon">
              <MdLocationCity className="input-icon" />
              <input
                type="text"
                placeholder="City *"
                value={formValues.city}
                onChange={(e) => handleInputChange("city", e.target.value)}
                required
              />
            </div>
            {formErrors.city && (
              <div className="errorbox">{formErrors.city}</div>
            )}
          </div>

          <div className="input-with-icon" style={{ flexDirection: "column" }}>
            <div className="input-with-icon">
              <MdMarkunreadMailbox className="input-icon" />
              <input
                type="text"
                placeholder="Postal Code / Zip *"
                value={formValues.postalCode}
                onChange={(e) =>
                  handleInputChange("postalCode", e.target.value)
                }
                required
              />
            </div>
            {formErrors.postalCode && (
              <div className="errorbox">{formErrors.postalCode}</div>
            )}
          </div>
        </div>

        <div className="details-form-row">
          <div className="input-with-icon" style={{ flexDirection: "column" }}>
            <div className="input-with-icon">
              <MdMap className="input-icon" />
              <RegionDropdown
                country={formValues.selectedCountry}
                value={formValues.selectedRegion}
                onChange={(val) => handleInputChange("selectedRegion", val)}
                required
                className="region-dropdown"
                placeholder="Select Region *"
                countryValueType="short"
              />
            </div>
            {formErrors.selectedRegion && (
              <div className="errorbox">{formErrors.selectedRegion}</div>
            )}
          </div>

          <div className="input-with-icon" style={{ flexDirection: "column" }}>
            <div className="input-with-icon">
              <MdPublic className="input-icon" />
              <CountryDropdown
                value={formValues.selectedCountry}
                onChange={(val) => handleInputChange("selectedCountry", val)}
                className="country-dropdown"
                required
                valueType="short"
                placeholder="Select Country *"
              />
            </div>
            {formErrors.selectedCountry && (
              <div className="errorbox">{formErrors.selectedCountry}</div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default Details;
