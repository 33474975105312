import React from "react";
import "./Upsell.css";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import CustomButton from "../CustomButton/CustomButton";

const Upsell = ({
  isUpsellSelected,
  setUpsellChecked,
  upsell,
  handleUpsellRedirect
}) => {
  return (
    <div
      className="upsell-item"
      onClick={() => setUpsellChecked(!isUpsellSelected)}
    >
      <div className="upsell-content">
        {upsell.type === "Standard" ? (
          <div className="upsell-checkbox-wrpper">
            <CustomCheckbox isSelected={isUpsellSelected} />
            <span className="upsell-title">{upsell.title}</span>
          </div>
        ) : (
          <div style={{ alignItems: "center" }}>
            <CustomButton
              color="#ef7c00"
              invertColorScheme
              label="Donate"
              onClick={() => handleUpsellRedirect()}
            />
            <p className="upsell-title">{upsell.title}</p>
          </div>
        )}
        <p>{upsell.description}</p>
      </div>
      {upsell.backgroundImage && (
        <div
          style={{
            width: "200px",
            height: "200px",
            borderRadius: "20px",
            backgroundImage: upsell.backgroundImage
              ? `url(${upsell.backgroundImage})`
              : "none",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundColor: upsell.backgroundImage ? "transparent" : "white"
          }}
        >
          {upsell.iconSVGCode && (
            <div
              style={{
                background: "white",
                borderRadius: "100px",
                float: "right",
                top: "10px",
                position: "relative",
                right: "7px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
              className="upsell-image"
              dangerouslySetInnerHTML={{ __html: upsell.iconSVGCode }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Upsell;
