// PaymentMethodSelection.jsx
import React from "react";
import "./Checkout.css";

const PaymentMethodSelection = ({ paymentMethod, setPaymentMethod }) => {
  const methods = ["Credit Card", "Mobile Pay", "Online Banking"];
  return (
    <div className="payment-selection-container">
      <div
        className="title"
        style={{ color: "#ef7c00", fontSize: "2.8rem", textAlign: "center" }}
      >
        Payment Details
      </div>
      <div
        className="payment-selection-select-payment-method"
        style={{
          color: "#55504F",
          textAlign: "center",
          fontSize: "2.1rem",
          fontWeight: 600
        }}
      >
        Select Payment Method
      </div>
      <div className="payment-selection-payment-optionsContainer">
        {methods.map((method, idx) => (
          <div
            className="payment-selection-payment-option"
            key={method}
            onClick={(e) => setPaymentMethod(method)}
          >
            <input
              name="paymentMethod"
              type="radio"
              value={method}
              checked={paymentMethod === method}
              id={`choice_${idx}`}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
            {/* <input className="gfield-choice-input" name="input_8" type="radio" value="Credit Card" checked="checked" /> */}

            <label
              htmlFor={`choice_${idx}`}
              className="payment-selection-inline-label"
            >
              {method.toUpperCase()}
              {method === "Credit Card" && (
                <img src="/assets/visa.svg" alt="Visa" />
              )}
              {method === "Online Banking" && (
                <img src="/assets/credtcard.svg" alt="Credit Card" />
              )}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentMethodSelection;
