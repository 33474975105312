// SubTotal.jsx
import React from "react";
import CustomSpinner from "../../../../../components/CustomSpinner/CustomSpinner"
import CustomButton from "../../../../../components/CustomButton/CustomButton";
import CustomPaymentMethod from "./CustomPaymentMethod";
import "./Checkout.css";

const SubTotal = ({
  isProcessingFeeSelected,
  isUpsellSelected,
  upsell,
  handleDonateClick,
  amount,
  processingFee,
  total,
  loading,
  paymentRequest,
  paymentMethod
}) => (
  <>
    <table id="form-summary">
      <tbody>
        <tr id="subtotal">
          <td>Subtotal</td>
          <td>{parseFloat(amount).toFixed(2)}</td>
        </tr>
        {isUpsellSelected && upsell && (
          <tr id="promoAmt">
            <td>Sadaqah</td>
            <td>{parseFloat(upsell.amount).toFixed(2)}</td>
          </tr>
        )}
        {isProcessingFeeSelected && (
          <tr id="fees">
            <td>Processing Fees (2.2% + $0.80)</td>
            <td>{processingFee}</td>
          </tr>
        )}
        <tr id="total">
          <th>Total</th>
          <th>{total}</th>
        </tr>
      </tbody>
    </table>
    <div className="gform_footer top_label">
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CustomSpinner />
        </div>
      ) : paymentMethod === "Mobile Pay" ? (
        <CustomPaymentMethod paymentRequest={paymentRequest} />
      ) : (
        <CustomButton color="#ef7c00" label="DONATE" invertColorScheme onClick={handleDonateClick} />
      )}
    </div>
  </>
);

export default SubTotal;