// parsingUtils.js
/**
 * Splits a donation detail string of the format "Qurbani X 2 ($100); Something X 1 ($50)" into structured objects
 */
export const parseDetails = (input) => {
    if (typeof input !== "string") return [];
  
    return input
      .split(";")
      .map((entry) => {
        const trimmed = entry.trim();
        const match = trimmed.match(/^(.*?)\s*X\s*(\d+)\s*\(\$(\d+(\.\d{1,2})?)\)$/);
        if (!match) return null;
        const [, description, count, amount] = match;
        return {
          description: description.trim(),
          count: parseInt(count),
          amount: parseFloat(amount)
        };
      })
      .filter(Boolean);
  };