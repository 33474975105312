import "./Checkout.css";
import { CardElement } from "@stripe/react-stripe-js";
import { MdLock } from "react-icons/md";

const CreditCardInformation = () => {
  const cardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        backgroundColor: "#fff",
        "::placeholder": { color: "#aab7c4" }
      },
      invalid: { color: "#fa755a", iconColor: "#fa755a" }
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <h3>Credit Card Information</h3>
      <div style={{ marginBottom: "1em", textAlign: "center" }}>
        <MdLock className="lock-icon" /> This is a secure SSL encrypted payment.
      </div>
      <div className="card-element-wrapper">
        <CardElement options={cardElementOptions} />
      </div>
    </div>
  );
};

export default CreditCardInformation;
