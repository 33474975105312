// paymentUtils.js
import { showErrorToast } from "./toastify";
import { parseDetails } from "./parsingUtils";
import {
  verifyPaymentIntent,
  updatePaymentIntent,
  createPaymentIntent,
  updateUpsellPaymentIntent
} from "../api/payments";

/**
 * Initializes or retrieves an existing PaymentIntent from local storage.
 * If there's an existing ID that's not succeeded/canceled, re-use it.
 * Otherwise create a new one.
 */
export const initializeOrRetrievePaymentIntent = async ({
  localStorage,
  mainTotal,
  details,
  currentFund,
  currentCampaign,
  re_split,
  setClientSecret,
  setPaymentIntentId
}) => {
  try {
    console.log("Intialize/retrieve payment intent called");
    const existingId = localStorage.getItem("paymentIntentId");
    if (existingId) {
      const verified = await verifyPaymentIntent(existingId);
      if (
        verified.status &&
        !["succeeded", "canceled"].includes(verified.status)
      ) {
        setClientSecret(verified.clientSecret);
        setPaymentIntentId(existingId);
        return;
      }
      localStorage.removeItem("paymentIntentId");
    }

    const newIntent = await createPaymentIntent({
      amount: parseInt(mainTotal),
      details: parseDetails(details),
      fundId: currentFund?.fundId,
      campaignId: currentCampaign?.campaignId,
      re_split
    });

    setClientSecret(newIntent.clientSecret);
    setPaymentIntentId(newIntent.paymentIntentId);
    localStorage.setItem("paymentIntentId", newIntent.paymentIntentId);
  } catch (err) {
    console.log("Error initializing payment intent:", err);
    showErrorToast("Failed to initialize payment. Please try again.");
  }
};

/**
 * Processes upsell payment for "Sadaqah" or any additional donation after the main payment
 */
export const processUpsellPayment = async ({
  stripe,
  customerId,
  paymentMethodId,
  parentPaymentIntentId,
  parentSubscriptionId,
  upsellAmount,
  upsellData
}) => {
  if (!upsellAmount) return;
  try {
    const upsellIntent = await createPaymentIntent({
      amount: parseFloat(upsellAmount),
      details: [
        {
          description: `${upsellData?.title}`,
          amount: parseFloat(upsellAmount),
          count: 1
        }
      ],
      customerId
    });

    await updateUpsellPaymentIntent(upsellIntent.paymentIntentId, {
      parentPaymentIntentId,
      parentSubscriptionId,
      amount: parseFloat(upsellAmount),
      upsellData: {
        campaignId: parseInt(upsellData?.campaignId),
        fundId: 16
      }
    });

    const confirmUpsell = await stripe.confirmCardPayment(
      upsellIntent.clientSecret,
      {
        payment_method: paymentMethodId
      }
    );

    if (confirmUpsell.paymentIntent.status !== "succeeded") {
      showErrorToast("Upsell payment failed. Please try again.");
    }
  } catch (error) {
    console.log("Error processing upsell payment:", error);
    showErrorToast("Error processing upsell payment. Please try again.");
  }
};
