// Checkout.jsx
import React, { useEffect, useState } from "react";
import "./Checkout.css";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useLoadScript } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useDispatch } from "react-redux";

import { setImage } from "../../../../../store/slices/fundsAndCampaignsSlice";
import { verifyRecaptcha } from "../../../../../api/verification";
import { validateForm } from "../../../../../utils/formUtils";
import { showErrorToast } from "../../../../../utils/toastify";
import { initializeOrRetrievePaymentIntent } from "../../../../../utils/paymentUtils";

import CheckoutHeader from "./CheckoutHeader";
import PaymentMethodSelection from "./PaymentMethodSelection";
import CreditCardInformation from "./CreditCardInformation.jsx";
import Details from "./Details";
import Inscription from "./Inscription";
import ExtraChoice from "./ExtraChoice";
import SubTotal from "./SubTotal";
import Notes from "./Notes";
import Upsell from "../../../../../components/Upsell/Upsell.jsx"; // The Upsell component path as per your structure
import { usePaymentHandler } from "../../../../../hooks/usePaymentHandler.jsx";
import { useCheckoutData } from "../../../../../hooks/useCheckoutData.jsx";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const stripe = useStripe();
  const elements = useElements();

  const [paymentMethod, setPaymentMethod] = useState("Credit Card");
  const [isZakatSelected, setIsZakatSelected] = useState(false);
  const [optOutOfEmailsSelected, setOptOutOfEmailsSelected] = useState(false);
  const [isProcessingFeeSelected, setIsProcessingFeeSelected] = useState(false);
  const [isUpsellSelected, setUpsellChecked] = useState(false);
  const [inscription, setInscription] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);

  const [formValues, setFormValues] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    addressLine1: "",
    addressLine2: "",
    unitNumber: "",
    city: "",
    postalCode: "",
    selectedRegion: "",
    selectedCountry: "CA",
    inscription: ""
  });
  const [formErrors, setFormErrors] = useState({});

  // -- Retrieve all checkout data with the new hook
  const {
    donation,
    program,
    re_split,
    amount,
    frequency,
    details,
    currentFund,
    currentCampaign,
    processingFee,
    mainTotal,
    upsellAmount,
    total,
    upsellData
  } = useCheckoutData({
    isProcessingFeeSelected,
    isUpsellSelected
  });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"]
  });

  useEffect(() => {
    dispatch(setImage("/assets/bg-checkout.jpg"));
  }, [dispatch]);

  useEffect(() => {
    const isSub = frequency !== "One Time";
    setIsSubscription(isSub);

    if (!isSub) {
      initializeOrRetrievePaymentIntent({
        localStorage,
        mainTotal,
        details,
        currentFund,
        currentCampaign,
        re_split,
        setClientSecret,
        setPaymentIntentId
      });
    }
  }, [frequency, details, mainTotal, re_split, currentFund, currentCampaign]);

  // PaymentRequest for Google/Apple Pay
  useEffect(() => {
    if (!stripe) return;
    const pr = stripe.paymentRequest({
      country: "CA",
      currency: "cad",
      total: {
        label: "Total",
        amount: Math.round(parseFloat(total) * 100)
      },
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true
    });
    pr.canMakePayment().then((result) => {
      if (result) setPaymentRequest(pr);
    });
  }, [stripe, total]);

  // Use the universal payment handler hook
  const { handleGoogleApplePay, handleCardPayment, handleBankPayment } =
    usePaymentHandler({
      stripe,
      elements,
      clientSecret,
      paymentIntentId,
      isSubscription,
      isZakatSelected,
      optOutOfEmailsSelected,
      isProcessingFeeSelected,
      processingFee,
      upsellAmount,
      upsellData,
      re_split,
      formValues,
      amount,
      details,
      frequency,
      currentFund,
      currentCampaign,
      navigate,
      setLoading
    });

  // Attach Google/Apple Pay event
  useEffect(() => {
    if (!paymentRequest) return;
    paymentRequest.on("paymentmethod", handleGoogleApplePay);
    return () => {
      paymentRequest.off("paymentmethod", handleGoogleApplePay);
    };
  }, [paymentRequest, handleGoogleApplePay]);

  // Update PaymentRequest total if something changes
  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.update({
        total: {
          label: "Total",
          amount: Math.round(parseFloat(total) * 100)
        }
      });
    }
  }, [paymentRequest, total]);

  const handleDonateClick = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { isValid, errors } = validateForm(formValues);
    if (!isValid) {
      setFormErrors(errors);
      window.scrollTo(0, 0);
      setLoading(false);
      return;
    }

    if (!executeRecaptcha) {
      showErrorToast("reCAPTCHA is not ready. Please try again.");
      setLoading(false);
      return;
    }

    const token = await executeRecaptcha("checkout_action");
    try {
      const recaptchaRes = await verifyRecaptcha(token);
      if (!recaptchaRes.success || recaptchaRes.score < 0.5) {
        showErrorToast("reCAPTCHA verification failed. Please try again.");
        setLoading(false);
        return;
      }

      if (!stripe || !elements) {
        showErrorToast("Payment services not available. Please try again.");
        setLoading(false);
        return;
      }

      if (paymentMethod === "Online Banking") {
        await handleBankPayment();
      } else if (paymentMethod === "Credit Card") {
        await handleCardPayment(clientSecret);
      } else if (paymentMethod === "Mobile Pay") {
        showErrorToast("Please use the Mobile Pay button rendered above.");
      }
    } catch (err) {
      console.log("Donation Error:", err);
      showErrorToast("Error processing payment. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <CheckoutHeader
        amount={amount}
        frequency={frequency}
        donation={donation}
        program={program}
        details={details}
      />
      <div className="checkout-body">
        <PaymentMethodSelection
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
        />

        {paymentMethod === "Credit Card" && <CreditCardInformation />}

        {paymentMethod !== "Mobile Pay" && (
          <Details
            isLoaded={isLoaded}
            formValues={formValues}
            setFormValues={setFormValues}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
          />
        )}

        {currentCampaign?.allowInscription && (
          <Inscription
            inscription={inscription}
            setInscription={setInscription}
          />
        )}

        <ExtraChoice
          zakatEligible={currentCampaign?.zakatEligible}
          isZakatSelected={isZakatSelected}
          setIsZakatSelected={setIsZakatSelected}
          optOutOfEmailsSelected={optOutOfEmailsSelected}
          setOptOutOfEmailsSelected={setOptOutOfEmailsSelected}
          isProcessingFeeSelected={isProcessingFeeSelected}
          setIsProcessingFeeSelected={setIsProcessingFeeSelected}
        />

        {currentCampaign?.upsell && (
          <Upsell
            isUpsellSelected={isUpsellSelected}
            setUpsellChecked={setUpsellChecked}
            upsell={currentCampaign.upsell}
          />
        )}

        <SubTotal
          isProcessingFeeSelected={isProcessingFeeSelected}
          isUpsellSelected={isUpsellSelected}
          upsell={currentCampaign?.upsell}
          handleDonateClick={handleDonateClick}
          amount={amount}
          processingFee={processingFee}
          total={total}
          loading={loading}
          paymentRequest={paymentRequest}
          paymentMethod={paymentMethod}
        />
      </div>
      <Notes />
    </>
  );
};

export default Checkout;
