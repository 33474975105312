// useCheckoutData.js
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import slugify from "slugify";

/**
 * Custom hook for extracting all checkout-related data from search params and Redux.
 * Also computes derived values like processingFee, mainTotal, upsellAmount, total.
 */
export const useCheckoutData = ({
  isProcessingFeeSelected,
  isUpsellSelected
}) => {
  const [searchParams] = useSearchParams();

  const donation = decodeURIComponent(searchParams.get("donation") || "");
  const program = decodeURIComponent(searchParams.get("program") || "");
  const custom = decodeURIComponent(searchParams.get("custom") || "");
  const re_split = decodeURIComponent(searchParams.get("re_split") || "");
  const amount = searchParams.get("amount") || "0";
  const frequency = decodeURIComponent(searchParams.get("frequency") || "");
  const details = decodeURIComponent(searchParams.get("details") || "");

  const funds = useSelector((state) => state.fundsAndCampaigns.funds);
  const campaignBlocks = useSelector((state) =>
    state.fundsAndCampaigns.campaignBlocks
      .filter(
        (c) =>
          c?.slug.trim().toLowerCase() ===
          slugify(donation.trim().toLowerCase())
      )
      .map((c) => ({
        ...c,
        fundId: state.fundsAndCampaigns.campaignBlocks[0].showOnFundId,
        name: state.fundsAndCampaigns.campaignBlocks[0].name
      }))
  );

  const isCustomBlock = campaignBlocks.length > 0;
  const additionalCauses = useSelector(
    (state) => state.fundsAndCampaigns.additionalCauses
  );
  const campaigns = useSelector((state) => state.fundsAndCampaigns.campaigns);

  const currentFund =
    funds.find((f) => f.name === program) ||
    additionalCauses.find((f) => f.name === program);

  const currentCampaigns =
    campaigns.filter((c) => c.name === donation) ||
    additionalCauses.filter((c) => c.name === donation);

  const currentCampaign = isCustomBlock
    ? campaigns.find((c) => c.campaignId === campaignBlocks[0].campaignId)
    : currentCampaigns.find((c) => c.fundId === currentFund?.fundId);

  const processingFee = useMemo(() => {
    const base = parseFloat(amount) || 0;
    return (base * 0.022 + 0.8).toFixed(2);
  }, [amount]);

  const mainTotal = useMemo(() => {
    const base = parseFloat(amount) || 0;
    const fee = isProcessingFeeSelected ? parseFloat(processingFee) : 0;
    return (base + fee).toFixed(2);
  }, [amount, isProcessingFeeSelected, processingFee]);

  const upsellAmount = useMemo(() => {
    if (!isUpsellSelected || !currentCampaign?.upsell) return 0;
    return parseFloat(currentCampaign.upsell.amount) || 0;
  }, [isUpsellSelected, currentCampaign]);

  const upsellData = useMemo(() => {
    if (!isUpsellSelected || !currentCampaign?.upsell) return null;
    return currentCampaign.upsell;
  }, [isUpsellSelected, currentCampaign]);

  const total = useMemo(() => {
    return (parseFloat(mainTotal) + upsellAmount).toFixed(2);
  }, [mainTotal, upsellAmount]);

  return {
    donation,
    program,
    custom,
    re_split,
    amount,
    frequency,
    details,
    funds,
    campaignBlocks,
    isCustomBlock,
    additionalCauses,
    campaigns,
    currentFund,
    currentCampaigns,
    currentCampaign,
    processingFee,
    mainTotal,
    upsellAmount,
    total,
    upsellData
  };
};
