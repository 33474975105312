import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_API_URL;

export const createSubscriptions = async ({
  priceIds,
  paymentMethodId,
  isZakat,
  donorDetails,
  campaignId,
  fundId,
  inscription,
  processingFee,
  re_split,
  amount,
  description
}) => {
  console.log(amount)
  console.log(description)
  const response = await axios.post(`${baseURL}/payments/create-subscription`, {
    priceIds,
    paymentMethodId,
    isZakat,
    donorDetails,
    campaignId,
    fundId,
    inscription,
    processingFee,
    re_split,
    details: [
      {
        description: description,
        amount: parseFloat(amount),
        count: 1
      }
    ]
  });
  return response.data;
};

export const createPaymentIntent = async ({
  amount,
  details,
  customerId,
  paymentMethodType,
  fundId,
  campaignId,
  re_split
}) => {
  const response = await axios.post(
    `${baseURL}/payments/create-payment-intent`,
    {
      amount,
      details,
      customerId,
      paymentMethodType,
      fundId,
      campaignId,
      re_split
    }
  );
  return response.data;
};

export const verifyPaymentIntent = async (paymentIntentId) => {
  const response = await axios.get(
    `${baseURL}/payments/payment-intent/${paymentIntentId}`
  );
  return response.data;
};

export const updatePaymentIntent = async (paymentIntentId, updateData) => {
  const response = await axios.post(
    `${baseURL}/payments/payment-intent/${paymentIntentId}`,
    updateData
  );
  return response.data;
};

export const updateUpsellPaymentIntent = async (
  paymentIntentId,
  updateData
) => {
  const response = await axios.post(
    `${baseURL}/payments/payment-intent-upsell/${paymentIntentId}`,
    updateData
  );
  return response.data;
};

export const createSetupIntent = async ({
  paymentMethodType,
  donorDetails,
  intervalDescription
}) => {
  const response = await axios.post(`${baseURL}/payments/create-setup-intent`, {
    paymentMethodType,
    donorDetails,
    intervalDescription
  });
  return response.data;
};
