// usePaymentHandler.js
import { useCallback } from "react";
import { showErrorToast } from "../utils/toastify";
import { parseDetails } from "../utils/parsingUtils";
import {
  createPaymentIntent,
  updatePaymentIntent,
  createSetupIntent,
  createSubscriptions
} from "../api/payments";
import { processUpsellPayment } from "../utils/paymentUtils";
import { getFrequencyKey } from "../utils/frequencyUtils";

/**
 * Custom hook that handles all payment flows:
 * - Card
 * - ACSS (Online Banking)
 * - Google/Apple Pay
 * - Upsell logic
 */
export const usePaymentHandler = ({
  stripe,
  elements,
  clientSecret,
  paymentIntentId,
  isSubscription,
  isZakatSelected,
  optOutOfEmailsSelected,
  isProcessingFeeSelected,
  processingFee,
  upsellAmount,
  upsellData,
  re_split,
  formValues,
  amount,
  details,
  frequency,
  currentFund,
  currentCampaign,
  navigate,
  setLoading
}) => {
  // ** Google/Apple Pay Flow **
  const handleGoogleApplePay = useCallback(
    async (event) => {
      try {
        if (!stripe || !paymentIntentId || !clientSecret) {
          showErrorToast("Payment intent not found or Stripe not ready.");
          event.complete("fail");
          return;
        }

        // Update PaymentIntent with final details
        await updatePaymentIntent(paymentIntentId, {
          isZakat: isZakatSelected,
          re_split,
          optOutOfEmails: optOutOfEmailsSelected,
          processingFee: isProcessingFeeSelected ? parseFloat(processingFee) : 0
        });

        const { error, paymentIntent } = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: event.paymentMethod.id
          }
        );

        if (error) {
          showErrorToast("Payment failed. Please try again.");
          event.complete("fail");
          return;
        }

        if (paymentIntent?.status === "succeeded") {
          event.complete("success");

          const donorDetails = {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            email: formValues.email,
            phone: formValues.phone,
            addressLine1: formValues.addressLine1,
            addressLine2: formValues.addressLine2 || "",
            city: formValues.city,
            postalCode: formValues.postalCode,
            region: formValues.selectedRegion,
            country: formValues.selectedCountry,
            optOutOfEmails: optOutOfEmailsSelected
          };

          if (isSubscription) {
            const subRes = await createSubscriptions({
              paymentMethodId: paymentIntent.payment_method,
              isZakat: isZakatSelected,
              re_split,
              campaignId: currentCampaign?.campaignId,
              fundId: currentFund?.fundId,
              inscription: formValues.inscription || undefined,
              processingFee: isProcessingFeeSelected
                ? parseFloat(processingFee)
                : 0,
              donorDetails,
              amount,
              description: currentCampaign?.name
            });

            navigate(
              `/thank-you/${currentFund.slug}/${
                currentCampaign.slug
              }?firstName=${encodeURIComponent(
                formValues.firstName + " " + formValues.lastName
              )}&amount=${encodeURIComponent(
                amount
              )}&subscriptionIds=${subRes.subscriptions
                .map((s) => s.subscriptionId)
                .join(",")}`
            );
          } else {
            navigate(
              `/thank-you/${currentFund.slug}/${
                currentCampaign.slug
              }?firstName=${encodeURIComponent(
                formValues.firstName + " " + formValues.lastName
              )}&amount=${encodeURIComponent(amount)}&paymentIntentId=${
                paymentIntent.id
              }`
            );
          }
        } else {
          showErrorToast("Payment not completed. Please try again.");
          event.complete("fail");
        }
      } catch (err) {
        console.log("Error with Google/Apple Pay:", err);
        showErrorToast("An error occurred. Please try again.");
        event.complete("fail");
      }
    },
    [
      stripe,
      paymentIntentId,
      clientSecret,
      re_split,
      isZakatSelected,
      optOutOfEmailsSelected,
      isProcessingFeeSelected,
      processingFee,
      isSubscription,
      formValues,
      currentCampaign,
      currentFund,
      navigate,
      amount
    ]
  );

  // ** Card Payment Flow **
  const handleCardPayment = async (localStorageClientSecret) => {
    try {
      const cardElement = elements.getElement("card");
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement
      });

      if (error) {
        showErrorToast(error.message);
        return;
      }

      const paymentMethodId = paymentMethod?.id;
      if (!paymentMethodId) {
        showErrorToast("Payment method creation failed.");
        return;
      }

      if (!isSubscription) {
        // One-Time Card Payment
        const updatedDoc = await updatePaymentIntent(paymentIntentId, {
          ...formValues,
          isZakat: isZakatSelected,
          re_split,
          optOutOfEmails: optOutOfEmailsSelected,
          amount: parseFloat(amount),
          processingFee: isProcessingFeeSelected ? parseFloat(processingFee) : 0
        });

        const confirm = await stripe.confirmCardPayment(
          localStorageClientSecret,
          {
            payment_method: paymentMethodId,
            setup_future_usage: "off_session"
          }
        );

        if (confirm.paymentIntent?.status === "succeeded") {
          localStorage.removeItem("paymentIntentId");
          const customerId = updatedDoc?.updatedPaymentIntent?.customer;

          if (upsellAmount) {
            await processUpsellPayment({
              stripe,
              customerId,
              paymentMethodId,
              parentPaymentIntentId: confirm.paymentIntent.id,
              upsellAmount,
              upsellData
            });
          }

          navigate(
            `/thank-you/${currentFund.slug}/${
              currentCampaign.slug
            }?firstName=${encodeURIComponent(
              formValues.firstName + " " + formValues.lastName
            )}&amount=${encodeURIComponent(amount)}&paymentIntentId=${
              confirm.paymentIntent.id
            }`
          );
        } else {
          showErrorToast("Payment failed. Please try again.");
        }
      } else {
        // Subscription Card Payment
        const freqKey = getFrequencyKey(frequency, isProcessingFeeSelected);
        const parsed = parseDetails(details);
        const priceIds = [];

        parsed.forEach((d) => {
          const foundPrice = currentCampaign?.priceOptions?.pricePoints?.find(
            (p) => parseInt(p.amount) === parseInt(d.amount)
          );
          if (foundPrice?.stripePriceIds?.[freqKey]) {
            for (let i = 0; i < d.count; i++) {
              priceIds.push(foundPrice.stripePriceIds[freqKey]);
            }
          }
        });

        const subsRes = await createSubscriptions({
          priceIds,
          paymentMethodId,
          isZakat: isZakatSelected,
          campaignId: currentCampaign?.campaignId,
          fundId: currentFund?.fundId,
          inscription: formValues.inscription || undefined,
          re_split,
          processingFee: isProcessingFeeSelected
            ? parseFloat(processingFee)
            : 0,
          donorDetails: {
            ...formValues,
            optOutOfEmails: optOutOfEmailsSelected
          },
          amount,
          description: currentCampaign?.name
        });

        const { subscriptions, customerId } = subsRes;
        if (upsellAmount) {
          await processUpsellPayment({
            stripe,
            customerId,
            paymentMethodId,
            parentSubscriptionId: subscriptions[0]?.subscriptionId,
            upsellAmount,
            upsellData
          });
        }

        navigate(
          `/thank-you/${currentFund.slug}/${
            currentCampaign.slug
          }?firstName=${encodeURIComponent(
            formValues.firstName + " " + formValues.lastName
          )}&amount=${encodeURIComponent(
            amount
          )}&subscriptionIds=${subscriptions
            .map((s) => s.subscriptionId)
            .join(",")}`
        );
      }
    } catch (err) {
      console.log("Card payment error:", err);
      showErrorToast("Error processing card payment. Please try again.");
    }
  };

  // ** ACSS (Online Banking) Flow **
  const handleBankPayment = async () => {
    if (!isSubscription) {
      await handleBankOneTime();
    } else {
      await handleBankSubscription();
    }
  };

  const handleBankSubscription = async () => {
    try {
      const freqKey = getFrequencyKey(frequency, isProcessingFeeSelected);
      const parsed = parseDetails(details);
      const priceIds = [];

      parsed.forEach((d) => {
        const foundPrice = currentCampaign?.priceOptions?.pricePoints?.find(
          (p) => parseInt(p.amount) === parseInt(d.amount)
        );
        if (foundPrice?.stripePriceIds?.[freqKey]) {
          for (let i = 0; i < d.count; i++) {
            priceIds.push(foundPrice.stripePriceIds[freqKey]);
          }
        }
      });

      const intervalDescription = `You authorize us to debit your bank account for ${frequency.toLowerCase()} payments of $${parseFloat(
        amount
      ).toFixed(2)}`;

      const { clientSecret: setupSecret } = await createSetupIntent({
        paymentMethodType: "acss_debit",
        donorDetails: {
          ...formValues,
          optOutOfEmails: optOutOfEmailsSelected
        },
        intervalDescription
      });

      const { error, setupIntent } = await stripe.confirmAcssDebitSetup(
        setupSecret,
        {
          payment_method: {
            billing_details: {
              name: `${formValues.firstName} ${formValues.lastName}`,
              email: formValues.email,
              phone: formValues.phone,
              address: {
                line1: formValues.addressLine1,
                line2: formValues.unitNumber
                  ? `Unit ${formValues.unitNumber}, ${formValues.addressLine2}`
                  : formValues.addressLine2,
                city: formValues.city,
                state: formValues.selectedRegion,
                postal_code: formValues.postalCode,
                country: formValues.selectedCountry
              }
            }
          }
        }
      );

      if (error) {
        showErrorToast(error.message);
        return;
      }

      if (
        setupIntent.status === "succeeded" ||
        setupIntent.status === "processing"
      ) {
        console.log(currentCampaign);
        const subs = await createSubscriptions({
          priceIds,
          paymentMethodId: setupIntent.payment_method,
          isZakat: isZakatSelected,
          re_split,
          campaignId: currentCampaign?.campaignId,
          fundId: currentFund?.fundId,
          inscription: formValues.inscription || undefined,
          processingFee: isProcessingFeeSelected
            ? parseFloat(processingFee)
            : 0,
          donorDetails: {
            ...formValues,
            optOutOfEmails: optOutOfEmailsSelected
          },
          amount,
          description: currentCampaign?.name
        });

        const { subscriptions, customerId } = subs;
        if (upsellAmount) {
          await processUpsellPayment({
            stripe,
            customerId,
            paymentMethodId: setupIntent.payment_method,
            parentSubscriptionId: subscriptions[0]?.subscriptionId,
            upsellAmount
          });
        }

        navigate(
          `/thank-you/${currentFund.slug}/${
            currentCampaign.slug
          }?firstName=${encodeURIComponent(
            formValues.firstName + " " + formValues.lastName
          )}&amount=${encodeURIComponent(
            amount
          )}&subscriptionIds=${subscriptions
            .map((s) => s.subscriptionId)
            .join(",")}`
        );
      } else {
        showErrorToast("Setup failed. Please try again.");
      }
    } catch (err) {
      console.log("ACSS Subscription Error:", err);
      showErrorToast("Error creating ACSS subscription. Please try again.");
    }
  };

  const handleBankOneTime = async () => {
    try {
      const createdIntent = await createPaymentIntent({
        amount:
          parseFloat(amount) +
          (isProcessingFeeSelected ? parseFloat(processingFee) : 0),
        details: parseDetails(details),
        paymentMethodType: "acss_debit"
      });

      const { error, paymentIntent } = await stripe.confirmAcssDebitPayment(
        createdIntent.clientSecret,
        {
          payment_method: {
            billing_details: {
              name: `${formValues.firstName} ${formValues.lastName}`,
              email: formValues.email,
              phone: formValues.phone,
              address: {
                line1: formValues.addressLine1,
                line2: formValues.unitNumber
                  ? `Unit ${formValues.unitNumber}, ${formValues.addressLine2}`
                  : formValues.addressLine2,
                city: formValues.city,
                state: formValues.selectedRegion,
                postal_code: formValues.postalCode,
                country: formValues.selectedCountry
              }
            }
          }
        }
      );

      if (error) {
        showErrorToast(error.message);
        return;
      }

      if (["succeeded", "processing"].includes(paymentIntent?.status)) {
        navigate(
          `/thank-you/${currentFund.slug}/${
            currentCampaign.slug
          }?firstName=${encodeURIComponent(
            formValues.firstName + " " + formValues.lastName
          )}&amount=${encodeURIComponent(amount)}&paymentIntentId=${
            paymentIntent.id
          }`
        );
      } else {
        showErrorToast("Payment failed. Please try again.");
      }
    } catch (err) {
      console.log("ACSS One-Time Error:", err);
      showErrorToast(
        "Error processing ACSS one-time payment. Please try again."
      );
    }
  };

  return {
    handleGoogleApplePay,
    handleCardPayment,
    handleBankPayment
  };
};
