import React, { useEffect } from "react";
import "./Funds.css";
import MoreWaysToDonate from "../../../../../components/MoreWaysToDonate/MoreWaysToDonate";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setImage } from "../../../../../store/slices/fundsAndCampaignsSlice";
import FundAndCampaignCard, {
  hexToRgbA
} from "../../../../../components/FundAndCampaignCard/FundAndCampaignCard";

import Grove from "./grove.svg";

const Funds = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setImage("/assets/pac-donate-main.jpg"));
  }, [dispatch]);

  return (
    <>
      <div className="siteMain__innerLogo">
        <a href="https://www.pennyappeal.ca/" className="siteLogo__wrapper">
          <img
            width="440"
            height="104"
            src="https://donate.pennyappeal.ca/wp-content/uploads/2021/08/pac-horizontallogo-white.svg"
            className="siteLogo siteLogo--white"
            alt=""
            decoding="async"
          />
          <img
            width="440"
            height="104"
            src="https://donate.pennyappeal.ca/wp-content/uploads/2021/10/pac-logo-orange.svg"
            className="siteLogo siteLogo--default"
            alt=""
            decoding="async"
          />
        </a>
      </div>
      <section className="paddingBottom--regular section section--homePrograms">
        <DonateToCause />
        <FundList />
      </section>
      <section className="paddingBottom--regular section section--activeCauses">
        <div className="row paddingBottom--extraSmall sectionTitle">
          <MoreWays />
          <ActiveCauses />
        </div>
      </section>
      <section className="mostNeededCTA_wrapper">
        <MoreWaysToDonate
          color={"#ef7c00"}
          handleClick={() => navigate(`/program/where-most-needed`)}
        />
      </section>
      <Footer />
    </>
  );
};

export default Funds;

const DonateToCause = () => {
  return (
    <div className="row paddingBottom--small sectionTitle">
      <h1 style={{ fontSize: "28px", paddingBottom: "0vh" }}>
        Donate to a Cause{" "}
        <span style={{ color: "#ef7c00" }}>You Care About</span>
      </h1>
      <p className="subtitle">
        Click{" "}
        <a
          href="https://pennyappeal.ca/ogn/?_gl=1*1x8p6hd*_ga*MjMxNTIyMzcwLjE3MjU2NzM1NTM.*_ga_211WSTMLHZ*MTcyNzc0MDE1My45LjEuMTcyNzc0MzkwMS4zMi4wLjA."
          className="donate-link"
        >
          here
        </a>{" "}
        to donate as a business
      </p>
    </div>
  );
};

const FundList = () => {
  const navigate = useNavigate();
  const funds = useSelector((state) => state.fundsAndCampaigns.funds).filter(
    (fund) => fund.showOnWebsite
  );
  const additionalCauses = useSelector(
    (state) => state.fundsAndCampaigns.additionalCauses
  );

  const handleFundClick = (fund) => {
    const fundSlug = fund.slug;
    navigate(`/program/${fundSlug}`);
  };

  const combinedList = [...additionalCauses, ...funds];

  return (
    <div className="sectionOptions">
      {combinedList.map((fund) => (
        <FundAndCampaignCard
          name={fund.name}
          color={fund.color}
          hoverColor={fund.hoverColor}
          iconSVGCode={fund.iconSVGCode}
          image={fund.image}
          staticBackground={fund.staticBackground}
          outline={true}
          onClick={() => handleFundClick(fund)}
        />
      ))}
      <FundAndCampaignCard
        name={"Orange Grove Network"}
        color={"#325435"}
        hoverColor={"#325435"}
        iconSVGCode={Grove}
        grove
        image={
          "https://donate.pennyappeal.ca/wp-content/uploads/2024/04/re-checkoutnew.jpg"
        }
        staticBackground={
          "https://donate.pennyappeal.ca/wp-content/uploads/2024/04/re-checkoutnew.jpg"
        }
        outline={true}
        onClick={() =>
          (window.location.href =
            "https://donate.pennyappeal.ca/program/orange-grove-network/")
        }
      />
    </div>
  );
};

const MoreWays = () => {
  return (
    <>
      <h2>
        <span style={{ color: "#ef7c00" }}>More Ways to Donate</span>
      </h2>
      <p>
        <a
          href="https://pennyappeal.ca/more-ways-to-donate/"
          target="_blank"
          rel="noopener"
        >
          Click here
        </a>{" "}
        to see other ways you can donate to a cause.
      </p>
      <div style={{ marginBottom: "2em" }}></div>
      <p>&nbsp;</p>
      <h2>
        <span style={{ color: "#ef7c00" }}>
          <svg
            className="svg-inline--fa fa-fire"
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="fire"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            data-fa-i2svg=""
          >
            <path
              fill="currentColor"
              d="M89.3 156.3C113 115 143.2 77 170.5 50.4c18.7 18.7 40.9 47.2 60.1 71.7c3.8 4.8 7.4 9.5 10.9 13.9c4.6 5.8 11.7 9.2 19.1 9.1s14.4-3.6 18.9-9.5c3.3-4.3 7.7-10.8 12.3-17.4c2.6-3.8 5.3-7.6 7.8-11.2c5.6-7.9 10.5-14.5 14.4-19.1c20 20.8 41 53 57.4 88.4c17.7 38.2 28.6 77 28.6 106.3c0 103-78.8 181.4-176 181.4c-98.3 0-176-78.4-176-181.4c0-37.5 16.2-82.4 41.3-126.2zM199.5 11.6C183.3-3.8 158-3.9 141.8 11.5c-32 30.1-67 73.6-94.1 121C20.7 179.5 0 233 0 282.6C0 410.9 98.1 512 224 512c124.6 0 224-100.9 224-229.4c0-39.1-13.9-85.2-33.1-126.5C395.7 114.6 369.8 74.9 343 49c-16.3-15.8-42-15.8-58.3-.1c-7.9 7.6-17 20-24.3 30.3l-1.1 1.6C240.6 57 218.4 29.5 199.5 11.6zM225.7 416c25.3 0 47.7-7 68.8-21c42.1-29.4 53.4-88.2 28.1-134.4c-4.5-9-16-9.6-22.5-2l-25.2 29.3c-6.6 7.6-18.5 7.4-24.7-.5c-16.5-21-46-58.5-62.8-79.8c-6.3-8-18.3-8.1-24.7-.1c-33.8 42.5-50.8 69.3-50.8 99.4C112 375.4 162.6 416 225.7 416z"
            ></path>
          </svg>
          <br />
          Active Causes
        </span>
      </h2>
      <p>Where others are donating right now.</p>
    </>
  );
};

const ActiveCauses = () => {
  const navigate = useNavigate();
  const funds = useSelector((state) => state.fundsAndCampaigns.funds);
  const campaigns = useSelector((state) => state.fundsAndCampaigns.campaigns);
  const activeCauses = campaigns.filter((campaign) => campaign.activeCause);

  const handleActiveCauseClick = (cause) => {
    const fundThatCauseBelongsTo = funds.find(
      (fund) => fund.fundId === cause.fundId
    );
    const fundSlug = fundThatCauseBelongsTo.slug;
    const campaignSlug = cause.slug;
    navigate(`/program/${fundSlug}/${campaignSlug}`);
  };

  return (
    <>
      <div
        id="activeCauses"
        class="sectionOptions swiper-container swiper-container-initialized swiper-container-horizontal"
      >
        <div
          class="swiper-wrapper"
          id="swiper-wrapper-10f03858cf5a844a5"
          aria-live="polite"
        >
          {activeCauses.map((cause) => {
            return (
              <div
                class="swiper-slide swiper-slide-active"
                role="group"
                aria-label="1 / 3"
              >
                <a
                  onClick={() => handleActiveCauseClick(cause)}
                  class="causeOption analytics-main causeOption--active causeOption--hurricane-beryl-caribbean-sea"
                  style={{
                    "--color": cause.color,
                    "--hexColor": hexToRgbA(cause.color)
                  }}
                >
                  <div class="causeOption__inner">
                    <span class="causeOption__name">{cause.name}</span>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

const Footer = () => {
  return (
    <footer id="colophone" className="siteFooter">
      <div className="siteFooter__inner">
        <ul className="socials footerSocials">
          <li className="social social--facebook-f">
            <a
              href="https://www.facebook.com/pennyappealCA/"
              title="Visit us on Facebook"
              target="_blank"
              className="social__icon facebook-redirect"
            >
              <svg
                className="svg-inline--fa fa-facebook-f"
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="facebook-f"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"
                ></path>
              </svg>
            </a>
          </li>
          <li className="social social--twitter">
            <a
              href="https://www.twitter.com/pennyappealCA"
              title="Visit us on Twitter"
              target="_blank"
              className="social__icon twiiter-redirect"
            >
              <svg
                className="svg-inline--fa fa-twitter"
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="twitter"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                ></path>
              </svg>
            </a>
          </li>
          <li className="social social--instagram">
            <a
              href="https://www.instagram.com/pennyappealca/?igshid=1n6qn6sw3leiy"
              title="Visit us on Instagram"
              target="_blank"
              className="social__icon instagram-redirect"
            >
              <svg
                className="svg-inline--fa fa-instagram"
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="instagram"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                ></path>
              </svg>
            </a>
          </li>
          <li className="social social--youtube">
            <a
              href="https://www.youtube.com/channel/UCuXxlA0sG2mA64IgJthFYzA"
              title="Visit us on Youtube"
              target="_blank"
              className="social__icon youtube-redirect"
            >
              <svg
                className="svg-inline--fa fa-youtube"
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="youtube"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                ></path>
              </svg>
            </a>
          </li>
        </ul>

        <div className="copyright">
          <p>
            Penny Appeal is a registered charity with the Canada Revenue Agency.{" "}
            <span>CRA no. 827502741 RR0001</span>
          </p>
          <p>
            <a href="https://fikralabs.ca" target="_blank" rel="noopener">
              Website Made With{" "}
              <svg
                className="svg-inline--fa fa-heart"
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="heart"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8l0-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5l0 3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20-.1-.1s0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5l0 3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2l0-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"
                ></path>
              </svg>{" "}
              by <span>Fikra Labs</span>
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};
