// Inscription.jsx
import React from "react";
import { MdOutlinePending } from "react-icons/md";
import "./Checkout.css";

const Inscription = ({ inscription, setInscription }) => (
  <div style={{ marginBottom: "50px" }}>
    <div className="details-form-row">
      <div className="input-with-icon">
        <MdOutlinePending className="input-icon" />
        <input
          value={inscription}
          onChange={(e) => setInscription(e.target.value)}
          type="text"
          placeholder="Inscription"
          maxLength={1000}
        />
      </div>
    </div>
    <p className="credit-card-info-description" style={{ textAlign: "left" }}>
      {(inscription || "").length} out of 1000 max characters
    </p>
  </div>
);

export default Inscription;