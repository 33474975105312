import moment from "moment/moment";

export const shouldRenderUpsell = (currentCampaign) => {
  if (!currentCampaign?.upsell || currentCampaign.upsell.type !== "Standard") {
    return false;
  }

  const today = moment().startOf("day");

  const startDate = currentCampaign.startDate
    ? moment(currentCampaign.startDate).startOf("day")
    : null;

  const endDate = currentCampaign.endDate
    ? moment(currentCampaign.endDate).startOf("day")
    : null;

  // Both startDate and endDate are provided
  if (startDate && endDate) {
    return (
      today.isSameOrAfter(startDate, "day") &&
      today.isSameOrBefore(endDate, "day")
    );
  }

  // Only startDate is provided
  if (startDate) {
    return today.isSameOrAfter(startDate, "day");
  }

  // Only endDate is provided
  if (endDate) {
    return today.isSameOrBefore(endDate, "day");
  }

  // Neither startDate nor endDate is provided
  return true;
};

export function getTotalAmount(giftBreakdown) {
  return giftBreakdown.reduce((sum, item) => {
    return sum + item.amount;
  }, 0);
}
